/* eslint-disable camelcase */
import React, { createContext, useCallback, useContext, useMemo, useState, PropsWithChildren, useEffect } from 'react';
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import { useLocation } from 'react-router-dom';
import { SplitFeatureName } from 'src/config/splitio-sdk-config';
import { OrdersUserProperties } from 'src/interfaces/ExchangeOrder';
import { UserContext } from './UserContext';

export const BID_ORDER_SIDE = 'buy';
export const ASK_ORDER_TYPE = 'sell';

export enum orderStatus {
  opened = 'Open',
  pending = 'Pending',
  fulfilled = 'Fulfilled',
  partially_fulfilled = 'Partially Fulfilled',
  canceled = 'Canceled',
  failed = 'Failed',
}

export interface OrderProperties {
  uuid: string;
  createdAt: string;
  updatedAt: string;
  fulfilled_at?: string | null;
  status: string;
  fee?: string | null;
  fee_currency?: string | null;
  fee_coin_id?: string | null;
  buyer?: string | null;
  seller?: string | null;
  transaction_id?: string | null;
  exchange: string;
  asset: string;
  base_coin_id: string;
  asset_amount: number;
  total_price: number;
  quote_coin_id: string;
  unit_price: number;
  price_currency: string;
  type: string;
}
export const exchangeOrderDetailsInitialState: OrdersUserProperties = {
  uuid: '',
  createdAt: '',
  updatedAt: '',
  kyc_validated: false,
  username: '',
  selling_orders: [],
  buying_orders: [],
};

interface ExchangeContextInterface {
  isExchangeEnabled: boolean;
  isSidebarOpen: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsExchangeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  handleSidebarCloseClick: () => void;
  handleSidebarOpenClick: () => void;
}

const defaultExchangeContext = {
  isExchangeEnabled: false,
  isSidebarOpen: false,
  setIsSidebarOpen: () => {},
  setIsExchangeEnabled: () => {},
  handleSidebarCloseClick: () => {},
  handleSidebarOpenClick: () => {},
};

export const ExchangeContext = createContext<ExchangeContextInterface>(defaultExchangeContext);

export const ExchangeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isExchangeEnabled, setIsExchangeEnabled] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const user = useContext(UserContext);
  const location = useLocation();

  const { isReady } = useContext(SplitContext);
  const splitTreatments = useTreatments([SplitFeatureName.EXCHANGE_ENABLED_SPLIT]);
  const checkSplit = useCallback(
    (feature: SplitFeatureName) => isReady && splitTreatments[feature]?.treatment === 'on',
    [isReady, splitTreatments],
  );

  useEffect(() => {
    setIsExchangeEnabled(checkSplit(SplitFeatureName.EXCHANGE_ENABLED_SPLIT));
  }, [checkSplit, location.pathname, setIsExchangeEnabled, user]);

  const handleSidebarCloseClick = useCallback(() => {
    setIsSidebarOpen(false);
  }, [setIsSidebarOpen]);

  const handleSidebarOpenClick = useCallback(() => {
    setIsSidebarOpen(true);
  }, [setIsSidebarOpen]);

  const providerValues = useMemo(
    () => ({
      isExchangeEnabled,
      setIsExchangeEnabled,
      isSidebarOpen,
      setIsSidebarOpen,
      handleSidebarCloseClick,
      handleSidebarOpenClick,
    }),
    [
      isExchangeEnabled,
      setIsExchangeEnabled,
      isSidebarOpen,
      setIsSidebarOpen,
      handleSidebarCloseClick,
      handleSidebarOpenClick,
    ],
  );

  return <ExchangeContext.Provider value={providerValues}>{isReady && children}</ExchangeContext.Provider>;
};
