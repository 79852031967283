export interface OrderProperties {
  uuid: string;
  createdAt: string;
  updatedAt: string;
  fulfilled_at: string | null;
  status: string;
  trading_pair: string;
  maker: string;
  exchange: string;
  asset_amount: number;
  total_price: number;
  friendly_id: string;
  unit_price: number;
  side: string;
  type: string;
  completion_percentage: number;
  net_amount: number;
}

export const BID_ORDER_SIDE = 'buy';
export const ASK_ORDER_SIDE = 'sell';

export enum orderStatus {
  opened = 'Open',
  pending = 'Pending',
  fulFilled = 'Fulfilled',
  partially_fulfilled = 'partially_fulfilled',
  'partially fulfilled' = 'Partially Filled',
  canceled = 'Canceled',
  failed = 'Failed',
}

export interface OrdersUserProperties {
  uuid: string;
  createdAt: string;
  updatedAt: string;
  kyc_validated: boolean;
  username: string;
  trading_pair?: string;
  selling_orders: OrderProperties[];
  buying_orders: OrderProperties[];
}
export interface GetExchangeTradePerformance {
  coordinates: {
    volumes: [];
    avg_prices: [];
  };
  maxY: number;
}

export interface GetExchangeBalanceVariation {
  coin_id: string;
  time_range: string;
  variation: number;
}
